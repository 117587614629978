import browserUpdate from 'browser-update/update.npm';
import 'focus-visible/dist/focus-visible'; // Auto-executing, see : https://github.com/WICG/focus-visible

// See : https://github.com/browser-update/browser-update/wiki/Details-on-configuration
browserUpdate({
  required: {
    e: -2, // Edge
    i: 12, // IE
    f: -2, // Firefox
    s: -2, // Safari
    c: -2, // Chrome
  },
  insecure: true,
});
